import cardBackground1 from "./cardBackground1.webp";
import cardBackground2 from "./cardBackground2.webp";
import cardBackground3 from "./cardBackground3.webp";
import cardBackground4 from "./cardBackground4.webp";
import cardBackground5 from "./cardBackground5.webp";
import cardBackground6 from "./cardBackground6.webp";
import ArtistItemBg from "./rectangle86.avif";
import marketItemBg from "./rectangle87.avif";
export { marketItemBg };
export { ArtistItemBg };
export const cardBackground = [
  cardBackground1,
  "661a76f396ee82f9e6f0b580",
  cardBackground3,
  cardBackground4,
  cardBackground5,
  cardBackground6,
  ArtistItemBg,
];
