// import ArchitectureIcon from "./ArchitectureIcon.avif";
// import CalligraphyIcon from "./CalligraphyIcon.avif";
// import MarquetryIcon from "./MarquetryIcon.avif";
// import HandCroftsIcon from "./HandCroftsIcon.avif";
// import MiniatureIcon from "./MiniatureIcon.avif";
// import MusicsIcon from "./MusicsIcon.avif";
// import AntiqueIcon from "./AntiqueIcon.avif";
// import PhotographyIcon from "./PhotographyIcon.avif";
// import DigitalArtIcon from "./DigitalArtIcon.avif";
// import CarpetIcon from "./CarpetIcon.avif";
// import SculptorIcon from "./SculptorIcon.avif";
// import JeweleryIcon from "./JewelleryIcon.avif";
// import PaintingIcon from "./DrawingIcon.avif";

import ArchitectureBg from "./ArchitectureBg.avif";
// import CalligraphyBg from "./CalligraphyBg.avif";
// import MarquetryBg from "./MarquetryBg.avif";
// import HandcraftBg from "./HandcraftBg.avif";
// import MiniatureBg from "./MiniatureBg.avif";
// import MusicsBg from "./MusicBg.avif";
// import AntiqueBg from "./AntiqueBg.avif";
// import PhotographyBg from "./PhotographyBg.avif";
// import DigitalArtBg from "./DigitalArtBg.avif";
// import CarpetBg from "./CarpetBg.avif";
// import SculptureBg from "./SculptureBg.avif";
// import JewelryBg from "./JewelryBg.avif";
// import PaintingBg from "./PaintingBg.avif";
import Instrument from "./handcraft/Instrument.avif";
import soap from "./handcraft/Soap.avif";
import Basket from "./handcraft/Basket_Weaving.avif";
import Paper from "./handcraft/Paper_Bookbinding.avif";
import Ceramics from "./handcraft/Ceramics_Glasswork.avif";
import Woodwork from "./handcraft/Woodwork.avif";
import Metalwork from "./handcraft/Metalwork.avif";
import Textile from "./handcraft/Textile_Arts.avif";
export const contests = [
  {
    name: "No Contest",
    id: "",
  },
  {
    name: "Artogenia's Annual Painting Contest 2024",
    id: "668f02d0b755b136a9e1f6b7",
  },
];
export const categories = [
  {
    id: "6628ed6795c8a6789eec5309",
    name: "Instrument Making",
    description: "",
    icon: "",
    bg: Instrument,
  },
  {
    id: "6628ed6795c8a6789eec5308",
    name: "Soap Making",
    description: "",
    icon: "",
    bg: soap,
  },
  {
    id: "6628ed6795c8a6789eec5307",
    name: "Basket Weaving",
    description: "",
    icon: "",
    bg: Basket,
  },
  {
    id: "6628ed6795c8a6789eec5306",
    name: "Paper and Bookbinding",
    description: "",
    icon: "",
    bg: Paper,
  },
  {
    id: "6628ed6795c8a6789eec5305",
    name: "Ceramics and Glasswork",
    description: "",
    icon: "",
    bg: Ceramics,
  },
  {
    id: "6628ed6795c8a6789eec5304",
    name: "Woodwork",
    description: "",
    icon: "",
    bg: Woodwork,
  },
  {
    id: "6628ed6795c8a6789eec5303",
    name: "Metalwork",
    description: "",
    icon: "",
    bg: Metalwork,
  },
  {
    id: "6628ed6795c8a6789eec5302",
    name: "Textile Arts",
    description: "",
    icon: "",
    bg: ArchitectureBg,
  },
];
// export const categories = [
//   // {
//   //   bg: ArchitectureBg,
//   //   icon: ArchitectureIcon,
//   //   name: "Architecture",
//   //   id: "65a663a62981636b6a5201d9",
//   // },
//   {
//     bg: CalligraphyBg,
//     icon: CalligraphyIcon,
//     name: "Calligraphy",
//     id: "65895e48e807adb89bb31286",
//   },
//   {
//     bg: MarquetryBg,
//     icon: MarquetryIcon,
//     name: "Marquetry",
//     id: "65a663912981636b6a5201d7",
//   },
//   // {
//   //   bg: HandcraftBg,
//   //   icon: HandCroftsIcon,
//   //   name: "Handcrafts",
//   //   id: "65a663bd2981636b6a5201da",
//   // },
//   // {
//   //   bg: MusicsBg,
//   //   icon: MusicsIcon,
//   //   name: "Musics",
//   //   id: "65a663c62981636b6a5201db",
//   // },
//   // {
//   //   bg: AntiqueBg,
//   //   icon: AntiqueIcon,
//   //   name: "Antique",
//   //   id: "65a663e92981636b6a5201de",
//   // },
//   {
//     bg: PhotographyBg,
//     icon: PhotographyIcon,
//     name: "Photography",
//     id: "65a663d02981636b6a5201dc",
//   },
//   {
//     bg: DigitalArtBg,
//     icon: DigitalArtIcon,
//     name: "Digital Art",
//     id: "65a663dc2981636b6a5201dd",
//   },
//   {
//     bg: MiniatureBg,
//     icon: MiniatureIcon,
//     name: "Miniature",
//     id: "65a663212981636b6a5201d5",
//   },
//   {
//     bg: CarpetBg,
//     icon: CarpetIcon,
//     name: "Carpet & Rug",
//     id: "65a6637d2981636b6a5201d6",
//   },
//   {
//     bg: SculptureBg,
//     icon: SculptorIcon,
//     name: "Sculpture",
//     id: "65a6639c2981636b6a5201d8",
//   },
//   // {
//   //   bg: JewelryBg,
//   //   icon: JeweleryIcon,
//   //   name: "Jewelry",
//   //   id: "65a664252981636b6a5201df",
//   // },
//   {
//     bg: PaintingBg,
//     icon: PaintingIcon,
//     name: "Painting",
//     id: "65895e2ce807adb89bb31285",
//   },
// ];
